import React, { useState } from "react";
import Button from "../Button/Button";

import { useOverlay } from "../../contexts/OverlayContext/OverlayContext";

import "./EmailForm.css";

function EmailForm() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { showOverlay } = useOverlay();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append('fields[email]', email);

    try {
      const response = await fetch(
        `https://assets.mailerlite.com/jsonp/1112858/forms/133185567406950059/subscribe`,
        {
          method: "POST",
          body: data,
        }
      );

      const result = await response.json();

      if (result.success) {
        showOverlay('Thanks for subscribing!');
        setEmail("");
      } else {
        showOverlay(result.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      showOverlay('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="email-form" onSubmit={handleSubmit}>
      <input
        type="email"
        placeholder="Enter your email address"
        name="fields[email]"
        value={email}
        required
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        type="submit"
        disabled={loading}
        onClickHandler={() => null}
        buttonText={loading ? "Submitting..." : "Get Free Guide"}
      />
    </form>
  );
}

export default EmailForm;
